import React, {useState, useEffect, useCallback} from 'react'
import './login.css'
import i18next from "i18next";
import {getToken, loginWithToken, loginWithPassword} from '../api/loginChat'
import {createHashHistory} from 'history';

import store from '../redux/store'
import {setMyUserInfo, setFetchingStatus} from '../redux/actions'
import {message} from '../components/common/alert'
import WebIM from '../utils/WebIM'
import loading from '../assets/loading.png'
import closeIcon from '../assets/Xmark@2x.png'
import eyeOpen from '../assets/eye@2x.png'
import eyeClose from '../assets/eye_slash@2x.png'
import {useLocation} from "react-router-dom";

const getValuesOfUrlQueryParam = (paramString) => {
    let params_arr = paramString.split('&');
    let obj = {}

    for (let i = 0; i < params_arr.length; i++) {
        let pair = params_arr[i].split('=');
        obj[pair[0]] = pair[1];
    }

    return obj
}

export default function Login() {

    const location = useLocation()
    const history = createHashHistory()
    const [notice, setNotice] = useState({
        show: false,
        text: ''
    })

    const [values, setValues] = useState({
        agoraId: '',
        nickName: '',
        password: '',
        showPassword: false
    });
    const [activeType, setActiveType] = useState('password')
    const [disabled, setdisabled] = useState(true)
    const [loginBtn, setLoginBtn] = useState(i18next.t('login-Login'))

    const login = useCallback(() => {
        setLoginBtn('')


        console.log('values', values)
        if (!values.agoraId) {
            return setNotice({show: true, text: 'agoraId is required'})
        } else if (!(values.nickName || values.password)) {
            return setNotice({show: true, text: 'password is required'})
        } else if (values.nickName.length > 32 || values.agoraId.length > 32) {
            return setNotice({show: true, text: 'agoraId is too long'})
        } else {
            setNotice({
                show: false
            })
        }
        // store.dispatch(setFetchingStatus(true))
        if (values.password) {
            getToken(values.agoraId, values.password).then((res) => {
                const {accessToken, agoraUid} = res
                WebIM.conn.agoraUid = String(agoraUid)
                loginWithToken(values.agoraId.toLowerCase(), accessToken).then(value => {

                }).catch(err => {
                    setNotice({show: true, text: 'Wrong Username or Password'})
                }).finally(_ => {
                    setTimeout(() => {
                        setLoginBtn(i18next.t('login-Login'))
                    }, 1500)
                })

                store.dispatch(setMyUserInfo({agoraId: values.agoraId, password: values.password}))
                sessionStorage.setItem('webim_auth', JSON.stringify({...values, accessToken, agoraUid}))
            }).catch((err) => {
                console.log('err', err);
                // store.dispatch(setFetchingStatus(false))
                setNotice({show: true, text: 'login fail'})
                setdisabled(true)
                setLoginBtn(i18next.t('login-Login'))
                // message.error('login fail.')
            })
        }
    }, [values])

    useEffect(() => {

        window.onload = () => {
            handleUrlUserToken()
            login()
        };

        const listener = function (event) {
            let curKey = event.which
            if (curKey === 13) {
                login()
            }
        }
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [login])

    const handleUrlUserToken = () => {
        const params = new URLSearchParams(location.search)
        const t = params.get("t")
        console.log('t ==> ', t);

        if (t !== '' && t !== null && typeof t !== undefined) {
            // window.location.href = 'https:/muafa.sa/doctor'
            const buff = Buffer.from(t, "base64");
            const str = buff.toString("utf8");

            // console.log('t ==> ',t);
            // console.log('t ==> ',str);
            const userDetails = getValuesOfUrlQueryParam(str)

            if (userDetails._u == '' || userDetails._u == null || typeof userDetails._u === undefined) {
                window.location.href = 'https:/muafa.sa/doctor'
            }

            if (userDetails._p == '' || userDetails._p == null || typeof userDetails._p === undefined) {
                window.location.href = 'https:/muafa.sa/doctor'
            }

            if (userDetails._to == '' || userDetails._to == null || typeof userDetails._to === undefined) {
                window.location.href = 'https:/muafa.sa/doctor'
            }

            if (userDetails._gid == '' || userDetails._gid == null || typeof userDetails._gid === undefined) {
                window.location.href = 'https:/muafa.sa/doctor'
            }

            setValues({
                ...values,
                agoraId: userDetails._u,
                password: userDetails._p,
                chat_to: userDetails._to,
                group_id: userDetails._gid

            })

            values.agoraId = userDetails._u
            values.password = userDetails._p
            values.chat_to = userDetails._to
            values.group_id = userDetails._gid
        }
    }

    const handleChange = (prop) => (event) => {
        let value = event.target.value
        if (prop === 'agoraId') {
            value = event.target.value.replace(/[^\w\.\/]/ig, '')
        }

        setValues({...values, [prop]: value});
    };
    const jumpToSignUp = () => {
        history.push('/signup')
    }
    useEffect(() => {
        const webim_auth = JSON.parse(sessionStorage.getItem('webim_auth'))
        if (webim_auth && webim_auth.password) {
            setValues({
                agoraId: webim_auth.agoraId || '',
                nickName: webim_auth.nickName || '',
                password: webim_auth.password || '',
            })
        } else {
            sessionStorage.removeItem('webim_auth')
        }
    }, [])
    const handleClickClearagoraId = () => {
        setValues({
            ...values,
            agoraId: ''
        })
    }
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        })
        if (activeType === 'password') {
            setActiveType('text')
        } else {
            setActiveType('password')
        }
    }


    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }
    // const { agoraId , password } = values
    useEffect(() => {
        if (values.agoraId && values.password) {
            setdisabled(false)
        } else {
            setdisabled(true)
        }
    }, [values.agoraId, values.password])
    return (
        <div className='login-container'>
            <div className='login-form'>
                <div className='login-form-icon'></div>
                <div className='login-form-AC'></div>
                {notice.show ? <div className='login-form-notice'>
                    {notice.text}
                </div> : null}

                <span class="loader"></span>

            </div>
            <div className='login-copyright'>
                © 2022 Agora
            </div>
        </div>
    )
}


